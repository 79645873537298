<template>
  <div class="af-modal-card">
    <div class="modal-card__content">
      <p class="has-text-weight-bold is-size-4 mb-4">
        {{ $t("af:merchandise.basket.title") }}
      </p>
    </div>

    <div class="mb-4 merchant-container">
      <div class="basket-logo mr-4" v-if="merchantLogoUrl">
        <img class="is-block" :src="merchantLogoUrl" alt="merchant" />
      </div>

      <div class="is-flex-direction-column">
        <p class="mb-1 has-text-weight-bold">
          {{ purchase.merchantName }}
          <b-icon icon-pack="fa" icon="check-circle" class="is-blue-icon" />
        </p>
        <p class="has-text-grey">{{ purchase.merchantFullName }}</p>
      </div>
    </div>

    <div class="basket-content-container is-flex-direction-column">
      <div>
        <p class="has-text-weight-bold is-size-5 mb-4">
          {{ $t("af:merchandise.basket.content.title") }}
        </p>
        <!--        <p class="ml-auto modify-link">-->
        <!--          {{ $t("af:merchandise.basket.content.button.modify") }}-->
        <!--        </p>-->
      </div>

      <div v-for="(item, index) in purchase.items" :key="index">
        <p class="has-text-grey basket-item">{{ item.name }}</p>
        <p class="has-text-weight-bold basket-item ml-auto">
          {{ item.price | monify }} Ft
        </p>
      </div>

      <div class="mt-2">
        <p class="has-text-weight-bold mb-0">
          {{ $t("af:merchandise.basket.content.summary.label") }}
        </p>
        <p class="has-text-weight-bold ml-auto">
          {{ purchase.totalAmount | monify }} Ft
        </p>
      </div>
    </div>

    <div class="basket-financing-container is-flex-direction-column mt-4">
      <div>
        <p class="has-text-weight-bold is-size-5 mb-4">
          {{ $t("af:merchandise.basket.financing.title") }}
        </p>
        <!--        <p class="ml-auto modify-link">-->
        <!--          {{ $t("af:merchandise.basket.financing.button.modify") }}-->
        <!--        </p>-->
      </div>

      <div class="creditor-container mb-5">
        <div class="basket-logo mr-3" v-if="partnerLogoUrl">
          <img class="is-block" :src="partnerLogoUrl" alt="creditor" />
        </div>

        <div class="creditor-details is-flex-direction-column mt-3">
          <div>
            <p class="has-text-weight-bold mb-0">{{ purchase.offerName }}</p>
            <p class="has-text-weight-bold ml-auto">
              {{ roundToTwoDecimalPlaces(purchase.thm) }}%
            </p>
          </div>

          <div>
            <p class="has-text-grey basket-item">
              {{ purchase.partnerFullName }}
            </p>
            <p class="has-text-grey ml-auto basket-item">
              {{ $t("af:merchandise.basket.financing.thm.label") }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <p class="has-text-grey basket-item">
          {{ $t("af:merchandise.basket.financing.down_payment.label") }}
        </p>
        <p class="has-text-weight-bold basket-item ml-auto">
          {{ purchase.downPayment | monify }} Ft
        </p>
      </div>

      <div>
        <p class="has-text-grey basket-item">
          {{ $t("af:merchandise.basket.financing.amount.label") }}
        </p>
        <p class="has-text-weight-bold basket-item ml-auto">
          {{ purchase.loanAmount | monify }} Ft
        </p>
      </div>

      <div v-if="purchase.extraAmount > 0">
        <p class="has-text-grey basket-item">
          {{ $t("af:merchandise.basket.financing.extra_amount.label") }}
        </p>
        <p class="has-text-weight-bold basket-item ml-auto">
          {{ purchase.extraAmount | monify }} Ft
        </p>
      </div>

      <div>
        <p class="has-text-grey basket-item">
          {{ $t("af:merchandise.basket.financing.duration.label") }}
        </p>
        <p class="has-text-weight-bold basket-item ml-auto">
          {{ purchase.duration }} hónap
        </p>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BasketContent",
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      merchantLogoUrl: null,
      partnerLogoUrl: null,
    };
  },
  async mounted() {
    this.merchantLogoUrl = await this.getLogoUrl(this.purchase.merchantLogo);
    this.partnerLogoUrl = await this.getLogoUrl(this.purchase.partnerLogo);
  },
  computed: {
    ...mapGetters({
      fileGetter: "drawer/getFile",
    }),
  },
  methods: {
    ...mapActions({
      fetchFile: "drawer/downloadPublic",
    }),
    async getLogoUrl(logoId) {
      if (!logoId) {
        return null;
      }

      try {
        let blob = this.fileGetter(logoId);
        if (!blob) {
          await this.fetchFile(logoId);
          blob = this.fileGetter(logoId);
        }

        if (blob) {
          return URL.createObjectURL(blob);
        }
      } catch (e) {
        console.warn("Couldn't resolve logo blob!");
      }
      return null;
    },
    roundToTwoDecimalPlaces(number) {
      if (number !== null && number !== undefined) {
        return Number(number).toFixed(2);
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-modal-card {
  min-width: 520px;

  div {
    display: flex;
  }
}

.merchant-container,
.basket-content-container {
  border-bottom: 1px $grey-2 solid;
  padding-bottom: 1rem;
}

.modify-link {
  color: $blue;
  cursor: pointer;
}

.basket-item {
  font-size: 14px;
}

.creditor-container {
  background: #f5f7fb;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 6px;
}

.creditor-details {
  width: 100%;
}

.basket-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 2.5rem;
    min-width: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
  }
}

@media only screen and (max-width: 769px) {
  .af-modal-card {
    min-width: 0;
  }
}
</style>
